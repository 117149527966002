import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import {Link} from 'gatsby'
import SEO from '../components/seo'
import BrevoConversationsWidget from '../components/chat'
import FreeProductOffer from '../components/FreeProductOffer'
import Share from '../components/share'

const VerbTemplate = ({data, location}) => {
  const verb = data.verbsJson
  const {href} = location

  const getConjugationValue = (conjugation, pronoun) => {
    const sanitizedPronoun = pronoun
      .replace('/', '_')
      .replace("'", '')
      .toLowerCase()

    if (conjugation.tense.toLowerCase() === 'impératif') {
      return conjugation.conjugation[sanitizedPronoun] || ''
    }

    return conjugation.conjugation[sanitizedPronoun]
  }

  const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const filteredConjugations = verb.conjugations.filter(
    conjugation =>
      ![
        'subjonctif',
        'subjonctif présent',
        'subjonctif passé',
        'subjonctif imparfait',
        'subjonctif plus-que-parfait',
      ].includes(conjugation.tense.toLowerCase()),
  )

  const secondTableConjugations = verb.conjugations.filter(conjugation =>
    [
      'subjonctif',
      'subjonctif présent',
      'subjonctif passé',
      'subjonctif imparfait',
      'subjonctif plus-que-parfait',
    ].includes(conjugation.tense.toLowerCase()),
  )

  const renderConjugationListItem = (conjugation, pronoun, label) => {
    const value = getConjugationValue(conjugation, pronoun)
    if (!value) return null
    return (
      <li>
        <strong>{label}:</strong> {value}
      </li>
    )
  }

  const renderConjugationJe = (conjugation, pronoun, label) => {
    const value =
      getConjugationValue(conjugation, pronoun) ||
      getConjugationValue(conjugation, 'j_')
    if (!value) return null
    return (
      <li>
        <strong>{label}:</strong> {value}
      </li>
    )
  }

  const renderConjugationQueJe = conjugation => {
    const value =
      getConjugationValue(conjugation, 'que_je') ||
      getConjugationValue(conjugation, 'que_j_')
    if (!value) return null
    return (
      <li>
        <strong>Que je/j':</strong> {value}
      </li>
    )
  }

  return (
    <Layout>
      <SEO
        title={verb.seo.title}
        description={verb.seo.description}
        hasJsonLd
        article
      />
      <section style={{marginTop: '4rem'}} className="section">
        <div className="container" style={{marginTop: 25}}>
          <Share redirect={href} title={verb.seo.title} isVertical />
          <div className="has-text-centered" style={{marginBottom: '2rem'}}>
            <Link
              style={{margin: '8px auto', order: 0}}
              className="button is-medium is-primary"
              to="../"
            >
              <span style={{marginRight: '8px'}}>🔙</span> Volver a los verbos
            </Link>
          </div>
          <h1
            style={{order: 1}}
            className="title is-size-1-mobile has-text-centered"
          >
            {capitalizeFirstLetter(verb.infinitive)}
          </h1>

          {/* Significado */}
          <div
            className="card"
            style={{marginTop: '2rem', borderRadius: '8px'}}
          >
            <header
              className="card-header has-background-primary"
              style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
            >
              <p className="card-header-title has-text-white has-text-centered">
                Significado
              </p>
            </header>
            <div className="card-content">
              <div className="content has-text-centered">{verb.meaning}</div>
            </div>
          </div>

          {/* Uso */}
          <div
            className="card"
            style={{marginTop: '2rem', borderRadius: '8px'}}
          >
            <header
              className="card-header has-background-info"
              style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
            >
              <p className="card-header-title has-text-white has-text-centered">
                Uso
              </p>
            </header>
            <div className="card-content">
              <div className="content has-text-centered">{verb.usage}</div>
            </div>
          </div>

          {/* Ejemplos */}
          <div
            className="card"
            style={{marginTop: '2rem', borderRadius: '8px'}}
          >
            <header
              className="card-header has-background-link"
              style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
            >
              <p className="card-header-title has-text-white has-text-centered">
                Ejemplos
              </p>
            </header>
            <div className="card-content">
              <div className="content has-text-centered">
                {verb.examples.map((el, index) => (
                  <p key={index}>{el}</p>
                ))}
              </div>
            </div>
          </div>

          {/* Tabla de conjugación */}
          <div
            className="card"
            style={{marginTop: '2rem', borderRadius: '8px'}}
          >
            <header
              className="card-header has-background-warning"
              style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
            >
              <p className="card-header-title has-text-black has-text-centered">
                Tabla de conjugación
              </p>
            </header>
            <div className="card-content">
              <div
                className="table-container desktop-only"
                style={{borderRadius: '8px', overflow: 'hidden'}}
              >
                <table className="table is-fullwidth is-striped is-hoverable is-bordered">
                  <thead className="has-background-primary has-text-white">
                    <tr className="has-text-centered">
                      <th>Tiempo</th>
                      <th>Je/J'</th>
                      <th>Tu</th>
                      <th>Il/elle/on</th>
                      <th>Nous</th>
                      <th>Vous</th>
                      <th>Ils/elles</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredConjugations.map(conjugation => (
                      <tr key={conjugation.tense} className="has-text-centered">
                        <td>{conjugation.tense}</td>
                        <td>
                          {getConjugationValue(conjugation, 'je') ||
                            getConjugationValue(conjugation, 'j_')}
                        </td>
                        <td>{getConjugationValue(conjugation, 'tu')}</td>
                        <td>
                          {getConjugationValue(conjugation, 'il_elle_on')}
                        </td>
                        <td>{getConjugationValue(conjugation, 'nous')}</td>
                        <td>{getConjugationValue(conjugation, 'vous')}</td>
                        <td>{getConjugationValue(conjugation, 'ils_elles')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className="table-container desktop-only"
                style={{
                  marginTop: '2rem',
                  borderRadius: '8px',
                  overflow: 'hidden',
                }}
              >
                <table className="table is-fullwidth is-striped is-hoverable is-bordered">
                  <thead className="has-background-info has-text-white">
                    <tr className="has-text-centered">
                      <th>Tiempo</th>
                      <th>Que je/j'</th>
                      <th>Que tu</th>
                      <th>Qu'il/elle/on</th>
                      <th>Que nous</th>
                      <th>Que vous</th>
                      <th>Qu'ils/elles</th>
                    </tr>
                  </thead>
                  <tbody>
                    {secondTableConjugations.map(conjugation => (
                      <tr key={conjugation.tense} className="has-text-centered">
                        <td>{conjugation.tense}</td>
                        <td>
                          {getConjugationValue(conjugation, 'que_je') ||
                            getConjugationValue(conjugation, 'que_j_')}
                        </td>
                        <td>{getConjugationValue(conjugation, 'que_tu')}</td>
                        <td>
                          {getConjugationValue(conjugation, 'qu_il_elle_on')}
                        </td>
                        <td>{getConjugationValue(conjugation, 'que_nous')}</td>
                        <td>{getConjugationValue(conjugation, 'que_vous')}</td>
                        <td>
                          {getConjugationValue(conjugation, 'qu_ils_elles')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className="conjugation-list mobile-only"
                style={{marginTop: '2rem'}}
              >
                {verb.conjugations.map(conjugation => (
                  <div
                    key={conjugation.tense}
                    className="conjugation-list-item"
                    style={{
                      marginBottom: '1.5rem',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    <h3
                      className="has-background-primary has-text-white has-text-centered"
                      style={{padding: '0.5rem'}}
                    >
                      {conjugation.tense}
                    </h3>
                    <ul className="has-text-centered">
                      {renderConjugationJe(conjugation, 'je', 'Je/J')}
                      {renderConjugationListItem(conjugation, 'tu', 'Tu')}
                      {renderConjugationListItem(
                        conjugation,
                        'il_elle_on',
                        'Il/elle/on',
                      )}
                      {renderConjugationListItem(conjugation, 'nous', 'Nous')}
                      {renderConjugationListItem(conjugation, 'vous', 'Vous')}
                      {renderConjugationListItem(
                        conjugation,
                        'ils_elles',
                        'Ils/elles',
                      )}
                      {renderConjugationQueJe(conjugation)}
                      {renderConjugationListItem(
                        conjugation,
                        'que_tu',
                        'Que tu',
                      )}
                      {renderConjugationListItem(
                        conjugation,
                        'qu_il_elle_on',
                        "Qu'il/elle/on",
                      )}
                      {renderConjugationListItem(
                        conjugation,
                        'que_nous',
                        'Que nous',
                      )}
                      {renderConjugationListItem(
                        conjugation,
                        'que_vous',
                        'Que vous',
                      )}
                      {renderConjugationListItem(
                        conjugation,
                        'qu_ils_elles',
                        "Qu'ils/elles",
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Free Product Offer */}
          <div
            className="card"
            style={{marginTop: '2rem', borderRadius: '8px'}}
          >
            <div className="card-content">
              <FreeProductOffer
                description={`Recibe cada semana una colección de sugerencias de aprendizaje para seguir adelante en tu progreso.`}
                image={data.FreeProductImage.childImageSharp.fluid}
                dataTarget="FreeProductModalBlog"
                modalId="FreeProductModalBlog"
                formSrc="https://e7b4fa4e.sibforms.com/serve/MUIFAFh-RDlH4kZiUa4IpWxsDizFvims_CjQS5RNoAGLiPE8Fr4OSrX-sx8BdgW6BLTcZzdRuJCRARxXxgl-Q_dkIliCe6O53Hg5kGSxrG81-lgMKyV8lj_xB200-5jxYIvCK5hnxT0r6vi-bAOfHcAHWZ4l_KrT4f31lF1Cmd8IcK8s8-hEoJnFv5NPWIUEFwu8q1H49IeqpX3P"
              />
            </div>
          </div>

          {/* Share Buttons */}
          <div style={{marginTop: '2rem'}}>
            <Share redirect={href} title={verb.seo.title} />
          </div>

          {/* Notification */}
          <div
            className="notification is-primary has-text-centered"
            style={{marginTop: '2rem', borderRadius: '8px'}}
          >
            <strong>
              Cómo podemos mejorar esta página para ti, háznoslo saber usando el
              chat.
            </strong>
          </div>
        </div>
      </section>
      <BrevoConversationsWidget />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    verbsJson(slug: {eq: $slug}) {
      seo {
        title
        description
      }
      infinitive
      conjugations {
        tense
        conjugation {
          je
          j_
          tu
          il_elle_on
          nous
          vous
          ils_elles

          que_je
          que_j_
          que_tu
          qu_il_elle_on
          que_nous
          que_vous
          qu_ils_elles
        }
      }
      meaning
      usage
      examples
    }
    FreeProductImage: file(name: {eq: "FreeProductImageVerb"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default VerbTemplate
